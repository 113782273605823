import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ActiveSessionService } from '../services/active-session.service';
import { AuthService } from '../services/auth.service';
import { map, take, tap } from 'rxjs';


export const authGuard: CanActivateFn = (route, state) => {

  const  allowedPermissions: number[]  = route.data['allowedPermissions'] as number[]
  
  const activeSessionService = inject(ActiveSessionService);
  const authService = inject(AuthService)
  const router = inject(Router);

  if (activeSessionService.SessionIsActive()) {

    return authService.ListPermissions().pipe(
      take(1),
      map(data => {
        if (data.isSuccess) {
          return allowedPermissions.some(permission => data.result.includes(permission));
        } else {
          return false;
        }
      }),
      tap(hasAccess => {
        if (!hasAccess) {
          //console.log('hasAccess...', hasAccess);          
          router.navigate(['/auth/login']);
        }
      })
    );

  } else {
    router.navigateByUrl('/auth/login');
    return false;
  }
}