import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { EPermissions } from './core/enum/EPermissions';


export const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.routes').then(r => r.AUTH_ROUTES)
	},
	{
		path: 'inventory',
		loadChildren: () => import('./modules/inventory/inventory.routes').then(r => r.INVENTORY_ROUTES),
		data:{ allowedPermissions: [EPermissions.Input, EPermissions.Output] },
		canActivate: [authGuard]
	},
	{
		path: 'report',
		loadChildren: () => import('./modules/report/report.routes').then(r => r.REPORT_ROUTES),
		data:{ allowedPermissions: [EPermissions.Reports] },
		canActivate: [authGuard]
	},
	{
		path: 'config',
		loadChildren: () => import('./modules/config/config.routes').then(r => r.CONFIG_ROUTES),
		data:{ allowedPermissions: [EPermissions.Configuration] },
		canActivate: [authGuard]
	},
    { 
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full'
    }
];
