import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActiveSessionService } from '../services/active-session.service';

export const bearerTokenInterceptor: HttpInterceptorFn = (request, next) => {
  
  let req = request;
  const activeSessionService = inject(ActiveSessionService)

  //Valida si la sesión esta activa (token existe)
  if(activeSessionService.SessionIsActive()){
    const authToken = 
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${activeSessionService.GetToken()}`
      }
    })
  }

  return next(req);
};


/*
export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const authService = inject(AuthenticationService);
  console.log('Intercepted');
  return next(req).pipe(
    catchError((err) => {
      console.log(err);
      if (err.status === 401) {
        console.log('authInterceptor 401');
        authService.logout();
        router.navigate(['/auth/signout']);
      }
      return throwError(() => new Error('Unauthorized Exception'));
    })
  );
};


*/
